import React, { useState, createContext, useEffect, useContext } from 'react'

const UserProfileContext = createContext()

export const UserProfileProvider = ({ children }) => {

    const [userProfile, setUserProfile] = useState([])
    const [mitmachenBgItems, setMitmachenBgItems] = useState([])
    
    const deleteUserItem = deletedItem => setUserProfile(userProfile.includes(deletedItem) && userProfile.filter(el => el.id !== deletedItem.id))

    useEffect(() => {
        console.log(`userProfile: `, userProfile)
    }, [userProfile])

    return (
        <UserProfileContext.Provider value={{ userProfile, setUserProfile, deleteUserItem, mitmachenBgItems, setMitmachenBgItems }}>
            {children}
        </UserProfileContext.Provider>
    )
}

export const useUserProfile = () => useContext(UserProfileContext)