import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useUserProfile } from '../../UserProfileProvider'
import { useWindowSize } from '@uidotdev/usehooks'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@ark-ui/react'
import { useStep } from '../../MitmachenStepProvider'

const Summary = () => {

  const { userProfile, mitmachenBgItems, setUserProfile, deleteUserItem } = useUserProfile()
  const { step, nextStep } = useStep()
  const size = useWindowSize()

  const summaryWrapperRef = useRef()
  const summaryContentRef = useRef()
  
  const [teacher, setTeacher] = useState([])
  const [isScrollable, setIsScrollable] = useState(false)
  const [buttonRouteTeacher, setButtonRouteTeacher] = useState([])
  
  const checkHeight = (parent, child) => setIsScrollable(child.offsetHeight > parent.offsetHeight ? true : false)

  useEffect(() => {
    // console.log(`SUMMARY --> step --> `, step)
    // console.log(`mitmachenBgItems --> `, mitmachenBgItems)
    setTeacher( [ ...new Set( [].concat.apply([], userProfile?.map( p =>  p.filteredTeacher) ) ) ] )
    setButtonRouteTeacher(userProfile?.map(p =>  p.showTeacher))
    checkHeight(summaryWrapperRef.current, summaryContentRef.current)
	}, [userProfile])
  
  // useEffect(() => {
  //   console.log(`teacher --> `, teacher)
	// }, [teacher])
  
  // useEffect(() => {
  //   console.log(`buttonRouteTeacher --> `, buttonRouteTeacher)
	// }, [buttonRouteTeacher])
  
  useEffect(() => {
    console.log(`userProfile --> `, userProfile)
    // nextStep()
    setTeacher( [ ...new Set( [].concat.apply( [], userProfile?.map( p =>  p.filteredTeacher) ) ) ] )
	}, [])
  
  return (
    <div className='relative z-10 flex justify-center w-full h-full px-4 md:px-6 lg:px-12 justifiy-center justify-items-center md:overflow-hidden'>
      <div ref={summaryWrapperRef} id='summaryWrapperRef' className={`flex flex-wrap content-center justify-center w-full md:w-1/3 h-full md:gap-x-12 ${isScrollable ? 'is-scrollable md:mb-24 md:overflow-y-auto' : ''}`}>
        
        <div ref={summaryContentRef} id='summaryContentRef' className={`flex flex-wrap justify-center w-full ${isScrollable ? 'h-full is-scrollable' : 'items-center content-center'}`}>
          <h1 className='w-full mb-4 text-lg text-center lg:text-xl'>Interessen /<br/>Befähigungen</h1>
          
          <div className='relative w-full px-9'>
            { userProfile?.length > 0 &&
              <Accordion 
                collapsible 
                multiple 
                className='relative flex flex-wrap justify-center w-full'
                onChange={ () => checkHeight(summaryWrapperRef.current, summaryContentRef.current) }
              >
                {userProfile.length >= 0 && userProfile.map((pd, i) => (
                  <AccordionItem 
                    key={i} 
                    value={i} 
                    className={`relative self-start w-full md:w-4/5 my-2 text-center leading-tight cursor-pointer grow-1`}
                  >
                    {api => (
                      <div className={`px-16 bg-white border-white ${api.isOpen ? 'rounded-3xl py-7' : 'rounded-full py-7'}`}>
                        <AccordionTrigger className='flex items-center justify-center w-full'>
                          <span className='font-bold'>{pd?.capability}</span>
                          
                          <div className='absolute z-20 inline-flex items-center justify-center gap-4 text-black right-4'>
                            <div className={`relative inline-flex items-center justify-center ${api.isOpen ? 'rotate-180' : '' }`}>
                              <span className="sr-only">Open</span>
                              <svg className="w-5 h-5" fill="none" viewBox="-0.055 6.05 19.619 17.279" stroke="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                                <g transform="matrix(1.415874, 0, 0, 1.415874, -7.236696, -2.300988)">
                                  <path d="M 5.644 8.645 L 12.344 15.36" />
                                  <path d="M 11.754 15.244 L 18.357 8.64" />
                                </g>
                              </svg>
                            </div>

                            <div className='relative inline-flex items-center justify-center' 
                              onClick={e => {
                                e.stopPropagation()
                                deleteUserItem(pd)}
                              }
                            >
                              <span className="sr-only">Close</span>
                              <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </div>
                          </div>
                        </AccordionTrigger>

                        <AccordionContent className='py-5'>
                          <div className='flex flex-col justify-center '>
                            {pd.capabilityContent?.map(stufe => {
                                return (
                                  stufe.capVals?.length >= 1 && 
                                    <div key={stufe.id} className='flex flex-col justify-center mb-3'>
                                      <p className='font-bold'>{stufe.name}:</p>
                                      {stufe.capVals?.map((val, i) => <p key={i} className=''>{val}</p>)}
                                    </div>
                                )
                            })}
                            <p className='font-bold'>Abschluss:</p>
                            <p>{pd?.education}</p>
                            <p>{pd?.educationDegree}</p>
                          </div>
                        </AccordionContent>
                      </div>
                    )}
                  </AccordionItem>
                ))}
              </Accordion> }
            
            <div className='flex flex-wrap justify-center w-full mx-auto'>
            {/* md:w-2/3 lg:w-4/5 xl:w-3/5 */}
              <Link to="/mitmachen/capabilities" className='relative block w-full px-2 py-3 my-2 leading-tight text-center border border-black rounded-full cursor-pointer md:w-2/3'>
                weitere Interessen /<br/> Befähigungen auswählen
              </Link>
              
              <Link 
                to={ buttonRouteTeacher.length !== 0 && buttonRouteTeacher.includes(true) && teacher?.length !== 0 ? '/lehrerberufe' : '/moeglichkeiten'} state={{ teacherFiltered: teacher?.length !== 0 && teacher, userProfile: userProfile, bg: mitmachenBgItems } } 
                className='relative block w-full px-4 py-3 my-2 leading-tight text-center border border-black rounded-full cursor-pointer md:w-2/3'
              >
                weiter zu meinen<br/>Möglichkeiten
              </Link>
            </div>
          </div>            
        </div>
      </div>
    </div>
  )
}

export default Summary