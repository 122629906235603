import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useInterimContent } from '../../ContentProvider'
import { useStep } from '../../MitmachenStepProvider'
import BackButton from '../BackButton/BackButton'
import { useUserProfile } from '../../UserProfileProvider'

const Capabilities = () => {

  const { state, pathname } = useLocation()

  const { generalData, capabilityData, capabilityContentData } = useInterimContent()
  const { userProfile, setUserProfile } = useUserProfile()
  const { step, nextStep } = useStep()

  const capRefs = useRef([])

  // const [userProfileItem, setUserProfileItem] = useState()
  const [capabilities, setCapabilities] = useState(null)
  const [selectedCapabilities, setSelectedCapabilities] = useState([])
  const [capabilityContent, setCapabilityContent] = useState(null)

  const filterSelectedCapabilities = () => {
    const selectedCaps = userProfile?.map(el => el.capability)
    // console.log(`selectedCaps --> `, selectedCaps)
    setSelectedCapabilities(selectedCaps)
    // userProfile?.filter(el => {
    //   // return (
    //   //   el.capability?.find(el => el._id === grade?._id)
    //   // )
    // })
  }

  // useEffect(() => {
    // console.log(`state --> `, state)step
    // nextStep()
	// }, [pathname])

  // useEffect(() => {
    // console.log(`CAPABILITIES step: `, step)
    // setBgActiveSlide(step++)
    // nextStep()
  // }, [])

  useEffect(() => {
    nextStep()
    // console.log(`capabilities: `, capabilities)
  }, [capabilities])
  
  // useEffect(() => {
  //   console.log(`capabilityContent: `, capabilityContent)
  // }, [capabilityContent])
  
  useEffect(() => {
    setCapabilities(capabilityData?.capabilities)
  }, [capabilityData])
  
  useEffect(() => {
    setCapabilityContent(capabilityContentData)
  }, [capabilityContentData])
  
  useEffect(() => {
    // console.log('userProfile', userProfile)
    filterSelectedCapabilities()
  }, [userProfile])
  
  return (
    <>
      <BackButton route={ '/mitmachen/lehrerin-werden' } />

      <div className={`relative w-full px-4 md:px-6 lg:px-12 grow-0`}>
        <div className='w-full grow-0'>
          <h1 className='text-lg lg:text-xl'>Interessen /<br/>Befähigungen</h1>
        </div>

        <div className='grid w-full mt-8 md:grid-cols-4 md:gap-x-12 justify-items-start'>
          { capabilities?.map((cap, i) => {

            const filteredCapabilityContent = capabilityContent?.filter(c => c.caps?.refCapability?.find(el => el._id === cap?._id))

            return (
              <>
                {selectedCapabilities?.length !== 0 ?
                  selectedCapabilities.includes(cap.name) ?
                    <div className='relative self-start block w-full px-5 py-2 my-2 font-bold text-left bg-white border border-white rounded-full md:my-3'>{cap.name}</div>
                    :
                    <Link 
                      key={i}
                      to="/mitmachen/grades" 
                      state={{ cap: cap, capContent: filteredCapabilityContent, userProfile: state?.userProfile }}
                      className={`block w-full self-start my-2 md:my-3 py-2 px-5 text-left relative border rounded-full border-black cursor-pointer`}
                    >
                      {cap.name}
                    </Link>
                  :
                  <Link 
                    key={i}
                    to="/mitmachen/grades" 
                    state={{ cap: cap, capContent: filteredCapabilityContent, userProfile: state?.userProfile }}
                    className={`block w-full self-start my-2 md:my-3 py-2 px-5 text-left relative border rounded-full border-black cursor-pointer`}
                  >
                    {cap.name}
                  </Link>
                }
              </>
                
              // <button
              //   key={i}
              //   className={`block self-start px-3 text-left relative m-3 border rounded-2xl md:border-black cursor-pointer`} // ${capRefs.current[i] === selectedCap
              //   // ref={el => cap.current[i] = el}
              //   // onClick={() => colRefs.current[i] !== activeColumn ? handleColumn(colRefs.current[i], i) : null}
              //   // onClick={() => handleCapacity(cap)}
              //   // onMouseEnter={() => setHoveredColumn(colRefs.current[i])}
              //   // onMouseLeave={() => setHoveredColumn(null)}
              // >              
              // </button>
            )
          }) }
        </div>
      </div>
    </>
  )
}

export default Capabilities


// useEffect(() => {    
  // console.log(`state --> `, state?.userProfile)
  // setBgItems(data)
  // `*[_type == "generalContent"][0] | { 
  //   _id, 
  //   name,
  //   'bg': bg->{ _id, name, backgroundImages },
  // }`

  // setUserProfileItem(state?.userProfile)
  // SanityClient
  //   .fetch(`*[_type == "capability"] | { 
  //     _id, 
  //     name, 
  //     'klassenstufen': grade { 
  //       refGrade[]->{
  //         _id,
  //         'stufe': name
  //       } 
  //     }
  //   }`)
  //   .then(data => {
  //     // console.log(`capability: `, data)
  //     setCapabilities(data)
  //   })
  //   .catch(console.error)
// }, [])