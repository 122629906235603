import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useWindowSize } from '@uidotdev/usehooks'
import Image from '../Image/Image'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useStep } from '../../MitmachenStepProvider'

const Backgrounds = ({ items, shuffle, autoplay }) => {

  const bgGallery = useRef(null)

  const size = useWindowSize()

  const { step, setStep } = useStep()
  
  useEffect(() => {
    // console.log('STEP BG', step)
    // console.log('BG', bgGallery.current?.splide?.index)
    bgGallery.current?.splide.Components.Controller.go(step === 0 ? step : '>')
	}, [step])
  
  // useEffect(() => {
    // console.log('items', items, Math.floor(Math.random() * items?.length) )
	// }, [items])
  
  // useEffect(() => {
  //   console.log('BG', bgGallery.current?.splide?.index)
	// }, [])
  
  return (
    <div className='absolute inset-0 z-0 w-full pointer-events-none h-d-screen md:h-screen'>
      {items?.length >= 1 &&
        <Splide
          ref={bgGallery}
          className='absolute inset-0 z-0 w-full h-full'
          tag='section'
          aria-label='BG'
          hasTrack={false}
          options={{
            type: 'fade',
            start: shuffle ? Math.floor(Math.random() * items?.length) : 0,
            autoplay: autoplay,
            rewind: true,
            speed: size.width >= 768 ? 100 : 1000,
            easing: 'linear',
            drag: false,
            interval: 3000,
            pauseOnHover: false,
            width: '100%',
            arrows: false,
            pagination: false
          }}
          // onMounted={ ( splide ) => { console.log( 'MOUNTED --> ', splide.index ) } }
        >  
          <SplideTrack>
            { items?.map(img => (
              <SplideSlide key={img._key}>
                <Image img={img.asset} bgImg={true} />
              </SplideSlide>
            )) }
          </SplideTrack>
        </Splide>
      }
    </div>
  )
}

export default Backgrounds