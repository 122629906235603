import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useInterimContent } from '../../ContentProvider'
import CapabilityContentButton from '../CapabilityContentButton/CapabilityContentButton'
import { useUserProfile } from '../../UserProfileProvider'
import { useStep } from '../../MitmachenStepProvider'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@ark-ui/react'
import BackButton from '../BackButton/BackButton'
import { useWindowSize } from '@uidotdev/usehooks'

const Grades = () => {

  const { state, pathname } = useLocation()
  const navigate = useNavigate()
  const { capabilityData, menuOpen } = useInterimContent()
  const { userProfile, setUserProfile, setKlassenStufenFachinhalte, klassenStufenFachinhalte } = useUserProfile()
  const { step, nextStep } = useStep()

  const size = useWindowSize()
  
  const userSelections = {
    id: userProfile?.length,
    capability: null,
    capabilityContent: null,
    filteredTeacher: [],
    showTeacher: null
  }

  const [userData, setUserData] = useState(userSelections)
  const [interest, setInterest] = useState(null)
  const [grades, setGrades] = useState(null)
  const [gradeCapContent, setGradeCapContent] = useState(null)
  const [filteredTeacher, setFilteredTeacher] = useState(null)
  const [showContinueBtn, setShowContinueBtn] = useState(false)
  const [showButtons, setShowButtons] = useState(null)
  const [showSummary, setShowSummary] = useState(false)
  const [educationFilter, setEducationFilter] = useState(false)
  const [capabilityContent, setCapabilityContent] = useState(null)
  const [selectedCapabilityContent, setSelectedCapabilityContent] = useState(null)

  // setShowTeacher(filteredTeacher && filteredTeacher.length >= 1 ? true : false)  
  // filterTeacher(selectedCapabilityContent)
  // const filterTeacherbyGrade = grade => setFilteredTeacher(teacher?.filter(teacher => teacher.relatedGrades?.refGrade.find(el => el._id === grade?._id)))

  const handleUserData  = (userProp, userVal, showTeacher) => {
    // console.log(userProp, userVal)
    setUserData(data => ({
      ...data,
      [userProp]: userVal,
      showTeacher: showTeacher,
      filteredTeacher: showTeacher ? filteredTeacher : []
    }))
  }

  const updateUserProfile = data => setUserProfile([...userProfile, data])

  // setSelectedCapabilityContent(selectedCapabilityContent.includes(cc) ? selectedCapabilityContent.filter(el => el._id !== cc?._id) : [...selectedCapabilityContent, cc._id])
  const updateCapabilityContent = (g, cc) => {
    const newSelectedCapabilityContent = selectedCapabilityContent.map(obj => {
      if (obj.id === g.id) {
        return obj.capVals.includes(cc.name) ? {...obj, capVals: obj.capVals.filter(val => val !== cc.name)} : {...obj, capVals: [...obj.capVals, cc.name]}
      }
      return obj
    })
    // console.log('newSelectedCapabilityContent', newSelectedCapabilityContent)
    setSelectedCapabilityContent(newSelectedCapabilityContent)
  }
  
  // useEffect(() => {
  //   nextStep()
  // }, [])
 
  // useEffect(() => {
  //   console.log('userData', userData)
  // }, [userData])

  useEffect(() => {
    // console.log(`userData: `, userData)
    if (userData?.education === true) {
      setShowButtons('educationType')
    }

    if (userData?.hasOwnProperty('educationType')) {
      setShowButtons('educationDegree')
    } 
    
    if (userData.hasOwnProperty('educationDegree')) {
      setShowButtons(null)
      setShowSummary(true)
    }
  }, [userData])

  useEffect(() => {
    showSummary && updateUserProfile(userData)
    showSummary && navigate('/mitmachen/summary', { state: { userData: userData } })
  }, [showSummary])
  
  // useEffect(() => {
  //   console.log('showButtons --> ', showButtons)
  // }, [showButtons])

  // useEffect(() => {
  //   console.log(`stepEducation: `, stepEducation)
  // }, [stepEducation])

  // useEffect(() => {
  //   console.log(`stepEducationType: `, stepEducationType)
  // }, [stepEducationType])
  
  // useEffect(() => {
  //   console.log(`stepEducationDegree: `, stepEducationDegree)
  // }, [stepEducationDegree])
  
  // useEffect(() => {
  //   // console.log('teacherIds --> ', teacherIds)
  //   setUserData(data => ({
  //     ...data,
  //     capability: interest?.name,
  //     filteredTeacher: teacherIds
  //   }))
  // }, [teacherIds])
  
  useEffect(() => {
    // console.log('selectedCapabilityContent --> ', selectedCapabilityContent)
    setShowContinueBtn(selectedCapabilityContent?.some(obj => obj.capVals.length !== 0) ? true : false)
    
    setUserData( data => ({
      ...data,
      capabilityContent: selectedCapabilityContent
    }))
  }, [selectedCapabilityContent])

  useEffect(() => {
    console.log('filteredTeacher --> ', filteredTeacher)
    // setTeacherIds(filteredTeacher?.map(el => el?._id))
    setUserData(data => ({
      ...data,
      capability: interest?.name,
      filteredTeacher: filteredTeacher
    }))
  }, [filteredTeacher])
  
  useEffect(() => {
    // console.log(`state --> `, state)
    setInterest(state?.cap)
    setGrades(state?.cap?.grades?.refGrade)
    setCapabilityContent(state?.capContent)
	}, [pathname, state])

  useEffect(() => {
    // console.log(`capabilityData --> : `, capabilityData)
    setFilteredTeacher(capabilityData?.teacher.filter(teacher => teacher.relatedCaps?.refCapability.find(el => el._id === interest?._id)))
  }, [capabilityData, interest])
  
  useEffect(() => {
    console.log('grades', grades)
    setGradeCapContent(grades && [...grades].sort((a, b) => a.sortOrder - b.sortOrder).map(grade => {
      return { 
        name: grade.stufe,
        id: grade._id,
        items: capabilityContent?.filter(cap => cap.grades?.refGrade.find(el => el._id === grade?._id))
      }
    }))

    setSelectedCapabilityContent(grades?.map(grade => {
      return { 
        id: grade._id,
        name: grade.stufe,
        capVals: []
      }
    }))
  }, [capabilityContent, grades])
  
  useEffect(() => {
    // console.log('educationFilter', educationFilter)
    setFilteredTeacher( filteredTeacher?.filter(teacher => teacher._id !== '23c3d965-2857-494d-b859-80c81f65e88a') )
  }, [educationFilter])
  
  // useEffect(() => {
  //   console.log('gradeCapContent', gradeCapContent)
  // }, [gradeCapContent])
  
  // useEffect(() => {
  //   console.log('userData', userData)
  // }, [])
  
  return (
    <>
      <BackButton />
      
      <button 
        className={`${showContinueBtn ? 'block' : 'hidden'} fixed right-4 z-50 block w-24 px-4 py-2 mx-auto text-base text-center bg-white border border-white rounded-full top-16 md:w-28 md:absolute md:left-auto md:right-8 md:bottom-auto md:top-0 md:px-8 md:py-6`}
        onClick={ () => {
          setShowButtons(filteredTeacher && filteredTeacher.length >= 1 ? 'education' : null)
          nextStep()
        } }>
        weiter
      </button>

      <div className={`relative w-full px-4 md:px-6 lg:px-12 z-10 grow-0 ${showButtons || menuOpen ? 'opacity-30' : ''} transition-opacity duration-200`}>
        <div className='relative flex items-center justify-between w-full grow-0'>
          <h1 className='text-lg md:text-xl'>{interest?.name}</h1>
        </div>

        <div className='w-full mt-4 md:justify-end md:flex md:flex-nowrap md:gap-x-12 md:justify-items-start md:mt-0'>
          {filteredTeacher?.length >= 1 && gradeCapContent?.map(grade => {
            // const gradeCapContent = capabilityContent?.filter(cap => cap.grades?.refGrade.find(el => el._id === grade?._id))
            // gradeCapContent?.length > 0 &&
            return (
              <div key={grade.id} className='w-full mb-8 md:w-1/3 md:mb-0'>
                <h3 className='text-lg md:text-xl md:px-6 md:my-10'>
                  {grade?.name}
                </h3>

                <div className='flex flex-col'>
                  {grade.items?.map(gc => 
                    <CapabilityContentButton 
                      key={gc._id} 
                      capContent={gc} 
                      updateCapabilityContent={updateCapabilityContent} 
                      grade={grade} 
                      interest={interest} 
                    /> 
                  )}

                  {/* <Accordion collapsible multiple>
                    {gradeCapContent?.map(gc => 
                      <AccordionItem key={gc._id} value={gc._id} className='relative self-start px-3 py-1 my-3 text-left border cursor-pointer grow-1 rounded-2xl md:border-black'>
                        <AccordionTrigger>{gc.name}</AccordionTrigger>
                        <AccordionContent>{gc.description?.description}</AccordionContent>
                      </AccordionItem>
                    )}
                  </Accordion> */}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {(showButtons !== null && !menuOpen) && 
        <div className='fixed inset-0 z-10 flex flex-wrap items-center justify-center md:absolute'> 
          
            {showButtons === 'education' &&
              <div className='w-full px-4 md:w-1/2 lg:w-1/3 md:px-0'>
                <button 
                  className='w-full px-3 py-6 my-2 text-base font-bold bg-white border border-white rounded-full shadow-xl'
                  onClick={() => handleUserData('education', true, true)}
                >
                  mit<br/>Ausbildung
                </button>
                
                <button 
                  className='w-full px-3 py-6 my-2 text-base font-bold bg-white border border-white rounded-full shadow-xl'
                  onClick={() => {
                    setUserData(data => ({
                      ...data,
                      education: false,
                      educationType: null,
                      educationDegree: 'ohne Ausbildung',
                      filteredTeacher: filteredTeacher,
                      showTeacher: false
                    }))
                    // handleEducation('education', false )
                    // handleEducationDegree('educationDegree', 'ohne Ausbildung')
                  }}
                >
                  ohne<br/>Ausbildung 
                </button>
              </div>
            }


            {showButtons === 'educationType' &&
              <div className='w-full px-4 md:w-1/2 lg:w-1/3 md:px-0'>
                <button 
                  className='w-full px-3 py-6 my-2 text-base font-bold bg-white border border-white rounded-full shadow-xl'
                  onClick={ () => {
                    setEducationFilter(true)
                    handleUserData('educationType', 'Berufsausbildung', true)
                  } }
                >
                  Berufsausbildung
                </button>

                <button 
                  className='w-full px-3 py-4 my-2 text-base font-bold bg-white border border-white rounded-full shadow-xl'
                  onClick={ () => handleUserData('educationType', 'Studium', true) }
                >
                  Studium
                  <span className='block text-xs font-normal'>an einer anerkannten Hochschule</span>
                </button>
              </div>
            }

            
            {showButtons === 'educationDegree' &&
              <div className='w-full px-4 md:w-1/2 lg:w-1/3 md:px-0'>
                <button 
                  className='w-full px-3 py-6 my-2 text-base font-bold bg-white border border-white rounded-full shadow-xl'
                  onClick={ () => handleUserData('educationDegree', userData?.educationType === 'Studium' ? 'abgeschlossenes Studium' : 'abgeschlossene Ausbildung', true) }
                >
                  {userData?.educationType === 'Studium' ?
                    <>
                      abgeschlossenes Studium
                      <span className='block text-xs font-normal'>Abschluss an einer anerkannten Hochschule</span>
                    </>
                    :
                    'abgeschlossene Ausbildung'}
                </button>
                
                <button 
                  className='w-full px-3 py-6 my-2 text-base font-bold bg-white border border-white rounded-full shadow-xl'
                  onClick={ () => handleUserData('educationDegree', 'ohne Abschluss', false) }
                >
                  ohne Abschluss
                </button> 
              </div>
            }
              
        </div>}
    </>
  )
}

export default Grades