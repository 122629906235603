import React from 'react'
import ContentPortableText from '../ContentPortableText/ContentPortableText'
import { Link } from 'react-router-dom'
import { useWindowSize } from '@uidotdev/usehooks'

function ContentColumn({title, content, btn, handleColumn, hoveredColumn, activeColumn, col, columns }) {

  const size = useWindowSize()

  return (  
    <>
      <div className={`relative hidden md:block w-full ${hoveredColumn || activeColumn ? `h-0` : `h-[calc(50%-40px)]`} transition-height duration-300`}></div>

      { title && 
        <h3 
          className={`${activeColumn ? 'w-full' : 'block ml-[50%] md:ml-0 -translate-x-1/2 w-auto'} ${activeColumn !== null && columns.current[col] !== activeColumn ? '' : ''} md:w-full md:translate-x-0 text-lg md:text-xl mb-0 md:mb-6 cursor-pointer`} 
          dangerouslySetInnerHTML={{ __html: title }}
          // onClick={ e => size.width < 768 ? (columns.current[col] !== activeColumn ? handleColumn(e?.type, columns.current[col]) : null) : null}>
          onClick={ e => size.width < 768 ? handleColumn(e, columns.current[col]) : null}>
        </h3> }

        <div className={`${(!activeColumn && size.width < 768) ? 'hidden' : ''} ${hoveredColumn || activeColumn ? 'h-full mt-3' : 'h-0 md:overflow-hidden'} md:h-auto transition-height duration-300 pr-4 ${hoveredColumn || activeColumn ? 'md:opacity-100' : 'md:opacity-0'} md:transition-opacity md:duration-300 delay-75`} >
          <ContentPortableText content={content} />

          { (hoveredColumn || activeColumn) && <div className='flex justify-center w-full h-full'>
            { btn && 
              <Link 
                to={`${btn?.internalLink !== null ? btn?.internalLink.slug.current : `/${btn?.route}`}`}
                state={{ section: btn?.route }}
                className='relative block w-2/3 px-2 py-3 mb-0 text-base text-center border border-black rounded-full cursor-pointer md:my-6 xl:my-12'
              >
                {btn?.linkText}
              </Link> }
          </div> }
        </div>
    </>
  )
}

export default ContentColumn