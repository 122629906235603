import React, { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { useLocation, useParams } from 'react-router-dom'
import { useInterimContent } from '../../ContentProvider'
import SanityClient from '../../SanityClient'
import Text from '../Text/Text'
import TextColumns from '../TextColumns/TextColumns'
import Backgrounds from '../Backgrounds/Backgrounds'
import BackButton from '../BackButton/BackButton'
import { useRef } from 'react'

function Page() {

  gsap.registerPlugin(ScrollToPlugin)

  const pageContentRef = useRef(null)
  
  const { state, pathname } = useLocation()
  const { slug } = useParams()

  const { menuOpen } = useInterimContent()

  const [contentData, setContentData] = useState(null)
  const [hideBackBtn, setHideBackBtn] = useState(false) 
  const [isActiveMobile, setIsActiveMobile] = useState(false) 

  const getSectionData = async () => {
    const data = await SanityClient.fetch(`*[_type == "section" && slug.current == "${slug}" ][0] | { 
      _id, 
      name,
      slug, 
      description,
      'bg': bg->{ _id, name, backgroundImages },
      text,
      columns,
      columnLeftTitle,
      columnLeft,
      columnLeftIsLink,
      'columnLeftLink': columnLeftLink{ internalLink->{ _id, slug, name}, route, linkText },
      'columnLeftButton': columnLeftButton{ internalLink->{ _id, slug, name}, route, linkText },
      columnCenterTitle,
      columnCenter,
      columnCenterIsLink,
      'columnCenterLink': columnCenterLink{ internalLink->{ _id, slug, name}, route, linkText },
      'columnCenterButton': columnCenterButton{ internalLink->{ _id, slug, name}, route, linkText },
      columnRightTitle,
      columnRight,
      columnRightIsLink,
      'columnRightLink': columnRightLink{ internalLink->{ _id, slug, name }, route, linkText },
      'columnRightButton': columnRightButton{ internalLink->{ _id, slug, name}, route, linkText },
    }`)
    // console.log('data', data)
    setContentData(data)
  }
  
  const scrollToAnchor = ref => {
    // console.log('ref', ref.offsetTop)
    setTimeout(() => {
      gsap.to(pageContentRef.current, {
        duration: .25,
        scrollTo: ref?.offsetTop,
        delay: .15,
        // onComplete: () => setScrollTop(false)
      })
    }, 200)
  }

  // useEffect(() => {
  //   console.log('hideBackBtn', hideBackBtn)
  // }, [hideBackBtn])

  // useEffect(() => {
  //   console.log('hideBackBtn', hideBackBtn)
  //   // scrollToAnchor(0)
  // }, [hideBackBtn])

  useEffect(() => {
    getSectionData(slug)
  }, [slug])
  
  return (
    <div className={`relative flex flex-wrap items-center w-full h-full z-10`}>

      <Backgrounds items={contentData?.bg.backgroundImages} shuffle={true} autoplay={false} />

      {!hideBackBtn && <BackButton hideBackBtn={hideBackBtn} /> }
      
      { contentData && 
        <div ref={pageContentRef} className={`${menuOpen ? 'opacity-10 pointer-events-none' : ''} transition-opacity duration-200 relative w-full px-4 md:px-6 overflow-y-auto h-content-mobile md:h-content lg:px-12`}>
          {contentData?.columns ? 
            <TextColumns 
              content={contentData} 
              isActiveMobile={isActiveMobile} 
              setIsActiveMobile={setIsActiveMobile} 
              scrollToAnchor={scrollToAnchor} 
              hideBackBtn={hideBackBtn} 
              setHideBackBtn={setHideBackBtn} 
            /> 

            : 
            
            <div className='relative w-full md:w-2/3'>
              <Text content={contentData?.text?.block} />
            </div>
          }
        </div> }
    
    </div>
  )
}

export default Page