import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Menu from '../Menu/Menu'
import { useInterimContent } from '../../ContentProvider'

import logo from '../../LAG_Logo.png'

const Layout = () => {

  const refLogo = useRef(null)

  const location = useLocation()
  const { state, pathname } = useLocation()
  const { menuOpen, setMenuOpen } = useInterimContent()

  const [displayLocation, setDisplayLocation] = useState(location)
  const [transitionStage, setTransistionStage] = useState("fadeIn")

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut")
  }, [location, displayLocation])

  useEffect(() => {
    setMenuOpen(false)
  }, [state, pathname])
  
  return (
    <div className={`relative w-full h-d-screen md:h-screen overflow-hidden`}>

      <Menu />
      
      <div 
        className={`relative w-full h-full ${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === 'fadeOut') {
            setTransistionStage('fadeIn')
            setDisplayLocation(location)
          }
        }}
      >
        <Outlet />
      </div>

      <div className='fixed z-20 block w-2/5 bottom-4 md:bottom-8 left-4 md:left-6 lg:left-12 md:w-2/12 xl:w-72'>
        <a href='https://waldorf-bw.de/' target='_blank' rel='noreferrer' className='block'>
          <img ref={refLogo} className='block w-full' src={logo} alt='LAG Logo' />
        </a>
      </div>
      
    </div>
  )
}

export default Layout