import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Link } from 'react-router-dom'
import { useWindowSize } from '@uidotdev/usehooks'
import ContentColumn from '../ContentColumn/ContentColumn'

const TextColumns = ({ 
  content, 
  isActiveMobile, 
  setIsActiveMobile, 
  scrollToAnchor, 
  hideBackBtn, 
  setHideBackBtn 
}) => {

  gsap.registerPlugin(ScrollToPlugin)

  const colRefs = useRef([])
  const colRef = useRef(null)

  const size = useWindowSize()

  const [contentColumns, setContentColumns] = useState(null)
  const [hoveredColumn, setHoveredColumn] = useState(null)
  const [activeColumn, setActiveColumn] = useState(null)

  const handleColumn = (ev, ref) => {
    if (size.width < 768) {
      if (ref === activeColumn) {
        setActiveColumn(null)
        setIsActiveMobile(false)
      } else {
        console.log(ref)
        setActiveColumn(ref)
        setIsActiveMobile(true)
        setHideBackBtn(!hideBackBtn)
        scrollToAnchor(ref?.el)
      }
      // setActiveColumn(ref!== activeColumn ? ref : null)
      // setIsActiveMobile(ref!== activeColumn ? true : false)
      // activeColumn === null && setHideBackBtn(!hideBackBtn)
    } else {
      if (ev?.type === 'mouseenter') {
        setHoveredColumn(ref)
      } else {
        ref.scroll({
          top: 0,
          behavior: 'smooth'
        })
        setHoveredColumn(null)
      }
    }
  }

  // useEffect(() => {
  //   colRef.current = myRef.current
  // }, [myRef.current])

  useEffect(() => {
      // console.log(`content --> `, content)
      const cols = [
        {
          title: content?.columnLeftTitle,
          txt: content?.columnLeft,
          link: content?.columnLeftIsLink && {
            url: content?.columnLeftLink?.internalLink, 
            route: content?.columnLeftLink?.route, 
            linkText: content?.columnLeftLink?.linkText
          },
          btn: content?.columnLeftButton
        },
        {
          title: content?.columnCenterTitle,
          txt: content?.columnCenter,
          link: content?.columnCenterIsLink && {
            url: content?.columnCenterLink?.internalLink, 
            route: content?.columnCenterLink?.route, 
            linkText: content?.columnCenterLink?.linkText
          },
          btn: content?.columnCenterButton
        },
        {
          title: content?.columnRightTitle,
          txt: content?.columnRight,
          link: content?.columnRightIsLink && {
            url: content?.columnRightLink?.internalLink, 
            route: content?.columnRightLink?.route, 
            linkText: content?.columnRightLink?.linkText
          },
          btn: content?.columnRightButton
        }
      ]
      setContentColumns(cols)
	}, [content])

  return (
    <>
      { (size.width < 768 && isActiveMobile && hideBackBtn) &&
        <div 
          className='fixed z-20 inline-flex items-center justify-center top-4 md:hidden' 
          onClick={() => {
            setHideBackBtn(!hideBackBtn)
            setActiveColumn(null)
          }}
        >
          <span className="sr-only">Close</span>
          <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div> }
      
      <div className={`relative w-full h-full flex flex-wrap md:flex-nowrap items-center z-10 md:divide-x md:divide-solid md:divide-black`}>
        { contentColumns?.map( (col, i) => {
          return (
            col.link ? 
              <Link 
                key={i}
                to={col.link?.url !== null ? `/${col.link?.url?.slug?.current}` : `${col.link?.route}`} //${i > 0 ? 'px-6 md:px-14' : ''} 
                className={`relative w-full justify-center flex items-center md:w-1/3 md:mb-12 mb-0 hide-scrollbar ${colRefs.current[i] === hoveredColumn ? `h-auto md:mt-0 overflow-y-auto` : `overflow-hidden`} md:h-full md:transition-height md:duration-300 cursor-pointer`}
              >
                <h3 className={`block mb-0 md:ml-0 w-3/5 md:w-full md:translate-x-0 text-lg md:text-xl text-left ${i === 0 ? 'md:pr-14' : (i === 2 ? 'md:pl-14' : 'md:px-14')} cursor-pointer`} dangerouslySetInnerHTML={{ __html: col.link?.linkText ? col.link?.linkText : col.title }}></h3>
              </Link> 

              :
              
              size.width < 768 ?
                <Link 
                  key={i} 
                  to={`/informieren-mobile`} 
                  state={{ title:col.title, content:col.txt?.block, btn:col.btn }} 
                  className='relative w-full'
                >
                  <h3 className={`block ml-[50%] md:ml-0 -translate-x-1/2 w-auto text-lg mb-0 cursor-pointer`} dangerouslySetInnerHTML={{ __html: col.title }}></h3>
                </Link>

                :
                
                <div
                  key={i}
                  ref={el => colRefs.current[i] = {el}}
                  className={`relative md:block w-full md:w-1/3 ${i === 0 ? 'md:pr-14' : (i === 1 ? 'md:px-14' : 'md:pl-14')} mb-0 hide-scrollbar ${colRefs.current[i] === (activeColumn || hoveredColumn) ? `h-auto md:mt-0 overflow-y-auto` : `overflow-hidden`} md:h-full md:transition-height md:duration-300 ${colRefs.current[i] === activeColumn && size.width < 768 ? 'min-h-[80vh]' : ''}`} 
                  onMouseEnter={ e => size.width >= 768 ? handleColumn(e, colRefs.current[i]) : null }
                  onMouseLeave={ e => size.width >= 768 ? handleColumn(e, colRefs.current[i]) : null }
                  onClick={ e => size.width < 768 ? handleColumn(e, colRefs.current[i]) : null }
                >
                  <ContentColumn 
                    title={col.title} 
                    content={col.txt?.block ? col.txt?.block : null} 
                    btn={col.btn}
                    hoveredColumn={colRefs.current[i] === hoveredColumn ? true : false}
                    activeColumn={colRefs.current[i] === activeColumn ? true : false}
                    handleColumn={handleColumn}
                    col={colRefs.current[i]} 
                    columns={colRefs}
                  />
                </div>
        )}) }
      </div>
    </>
  )
}

export default TextColumns