import React, { useEffect, useRef, useState } from 'react'

import { urlFor, getImgDimensions } from '../helpers'

const Image = ({img, bgImg}) => {

  // const ratio = imgWidth / imgHeight
  // const {imgWidth, imgHeight} = getImgDimensions(urlFor(img)).url().toString()
  // console.log('image', img, imgWidth, imgHeight)
  // const [showModal, setShowModal] = useState(false)
  // const {ww} = useWindowSize()

  // const url = urlFor().image(img).fit('max').fm('webp').url().toString()
  // const landscape = img?.width / img?.height > 1 ? true : false
  
  // const ratio = img?.width / img?.height

  // useEffect(() => {
  //   document.body.classList.toggle('overflow-hidden')
  // }, [showModal])

  // useEffect(() => {
  //   console.log('img', img, urlFor(img._ref))
  // }, [])

  return (
    img &&
      <>
        <figure 
          className={`relative block overflow-hidden bg-img ${bgImg ? 'absolute inset-0 z-0 object-cover w-full h-full' : ''}`}
          // onClick={() => isModal && ww > 768 ? setShowModal(true) : null}
        >
          {/* <SanityImage id={img._ref} baseUrl='https://cdn.sanity.io/images/c5oy99ui/development/' className='w-full'/> */}
          <picture>
            <source
              media='(min-width: 1920px)'
              srcSet={`${urlFor(img).quality(100).width(2560).url().toString()}`}
              // srcSet={urlFor(img).format('webp').url().toString()} 
            />
            <source 
              media='(min-width: 1440px)'
              srcSet={`${urlFor(img).quality(100).width(1920).url().toString()}`}
              // srcSet={urlFor(img).width(1920).format('webp').url().toString()} 
            />
            <source 
              media='(min-width: 769px)'
              srcSet={`${urlFor(img).quality(100).width(1440).url().toString()}`}
              // srcSet={urlFor(img).width(1440).format('webp').url().toString()} 
            />
            <source 
              media='(max-width: 768px)'
              srcSet={`${urlFor(img).quality(100).width(768).url().toString()}`} 
              // srcSet={urlFor(img).width(768).format('webp').url().toString()}
            />
            <img
              src='' //{urlFor(img).width(768).format('webp').url().toString()}
              loading='lazy'
              className={`${bgImg ? 'absolute inset-0 z-0 object-cover w-full h-full' : 'relative'} block w-full`}
              alt='' //{img.alt ? img.alt : altTxtSubtitle}
            />     
            {/* <source
              media='(min-width: 2560px)'
              srcSet={`${urlFor(img).format('webp').url().toString()}`}
              // srcSet={urlFor(img).format('webp').url().toString()} 
            /> */}            
          </picture>
        </figure>
      </>
    )
}

export default Image