import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@ark-ui/react'
import { blocksToPlainText } from '../helpers'
// import { useUserProfile } from '../../UserProfileProvider'

import axios from 'axios'

const UserProfileForm = ({ userData, teacherData, teacherItems }) => {

  const emailContent = {
    teacher: [],
    personalData: null
  }

  const [showForm, setShowForm] = useState(true)
  const [fieldTeacherData, setFieldTeacherData] = useState([teacherData])
  const [fieldUserData, setFieldUserData] = useState(userData)
  const [emailData, setEmailData] = useState(emailContent)

  const updateTeacher = teacher => {
    const findTeacher = fieldTeacherData?.find(el => el._id === teacher._id)
    setFieldTeacherData(findTeacher !== undefined ? [...fieldTeacherData.filter(el => el._id !== findTeacher?._id)] : [...fieldTeacherData, teacher])
  }
    
  const sendMailjetData = async data => {
    try {
      console.log(data)
      const res = await axios.post(`https://lag-user-form-service.onrender.com/send-email`, data)
      // const res = await axios.post(`http://localhost:3001/send-email`, data)
      
      console.log(res)
    } catch (e) {
      console.error(e)
    }
  }


  useEffect(() => {
    const personalDataMail = fieldUserData?.map(el => {
      return { 
        capability: el.capability,
        capabilityContent: el.capabilityContent,
        education: el.educationDegree
      }
    })    

    setEmailData(data => ({
      ...data,
      personalData: personalDataMail
    }))
	}, [fieldUserData])
  
  useEffect(() => {
    const teacherMail = fieldTeacherData?.map(el => {
      return { 
        id: el._id,
        name: el.name,
        description: blocksToPlainText(el.description?.block, '<br/>'),
        requirements: el.trainings?.refTraining
      }
    })

    setEmailData(data => ({
      ...data,
      teacher: teacherMail
    }))
	}, [fieldTeacherData])

  // useEffect(() => {
  //   console.log('fieldUserData', fieldUserData)
  //   console.log('fieldTeacherData', fieldTeacherData)
	// }, [fieldUserData, fieldTeacherData])
 
  useEffect(() => {
    console.log('emailData', emailData)
	}, [emailData])

  return (
    <div className='relative flex flex-wrap'>
      {showForm ?
        <Formik
          initialValues={{ 
            firstName: '', //falk
            lastName: '', //von heintschel
            email: '', //'info@vonheintschel.de',
            acceptPrivacy: false
          }}
          validate={values => {
            const errors = {}
            
            if (!values.acceptPrivacy) {
              errors.acceptPrivacy = 'Dies ist ein Pflichtfeld'
            } else if (!values.email) {
              errors.email = 'Dies ist ein Pflichtfeld'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Ungültige Email Adresse'
            }

            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            
            sendMailjetData({
              ...values, 
              ...emailData
            })
            
            setTimeout(() => {
              setSubmitting(false)
              setShowForm(false)
              console.log('emaildata ', emailData)
            }, 1000)
          }}
        >
          {({ isSubmitting }) => (
            <Form className='block w-full mb-12'>

              <Field 
                name="firstName" 
                type="text" 
                placeholder='Vorname' 
                className='block w-full px-4 py-3 mb-4 text-xs bg-transparent border border-black rounded-full md:text-base placeholder:text-black placeholder:text-center focus:shadow-transparent focus:border-black focus:outline-none focus:ring-black focus:ring-0' 
              />

              <Field 
                name="lastName" 
                type="text" 
                placeholder='Nachname' 
                className='block w-full px-4 py-3 mb-4 text-xs bg-transparent border border-black rounded-full md:text-base placeholder:text-black placeholder:text-center focus:shadow-transparent focus:border-black focus:outline-none focus:ring-black focus:ring-0' 
              />

              <Field 
                name="email" 
                type="email" 
                placeholder='E-Mail-Adresse' 
                className='block w-full px-4 py-3 mb-4 text-xs bg-transparent border border-black rounded-full md:text-base placeholder:text-black placeholder:text-center focus:shadow-none focus:border-black focus:outline-none focus:ring-black focus:ring-0' 
              />
              
              <ErrorMessage name="email" component="div" className='font-bold text-center'/>
              
              { userData?.length > 0 &&
                <div className='relative my-8 md:my-12'>
                  <p className='block mt-4 mb-2 text-xs text-center md:text-base md:mt-8'>Meine Angaben</p>

                  <Accordion collapsible multiple className='flex flex-wrap w-full'>
                    {userData.length >= 0 && userData.map((pd, i) => (
                      <AccordionItem
                        key={i} 
                        value={i} 
                        className={`relative self-start md:w-full my-2 text-center leading-tight cursor-pointer grow-1 w-full`}
                      >
                        {api => (
                          <div className={`px-16 bg-white border-white ${api.isOpen ? 'rounded-3xl py-6' : 'rounded-full py-6'}`}>
                            <AccordionTrigger className='flex items-center justify-center w-full'>
                              <>
                                <span className='font-bold'>{pd?.capability}</span>
                                
                                <div className='absolute z-20 inline-flex items-center justify-center gap-4 text-black right-8'>
                                  <div className={`relative inline-flex items-center justify-center ${api.isOpen ? 'rotate-180' : '' }`}>
                                    <span className="sr-only">Open</span>
                                    <svg className="w-5 h-5" fill="none" viewBox="-0.055 6.05 19.619 17.279" stroke="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                                      <g transform="matrix(1.415874, 0, 0, 1.415874, -7.236696, -2.300988)">
                                        <path d="M 5.644 8.645 L 12.344 15.36" />
                                        <path d="M 11.754 15.244 L 18.357 8.64" />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </>
                            </AccordionTrigger>

                            <AccordionContent className='py-5'>
                              <div className='flex flex-col justify-center '>
                                {pd.capabilityContent?.map(stufe => {
                                    return (
                                      stufe.capVals?.length >= 1 && 
                                        <div key={stufe.id} className='flex flex-col justify-center mb-3'>
                                          <p className='font-bold'>{stufe.name}:</p>
                                          {stufe.capVals?.map((val, i) => <p key={i} className=''>{val}</p>)}
                                        </div>
                                    )
                                })}
                                <p className='font-bold'>Abschluss:</p>
                                <p>{pd?.education}</p>
                                <p>{pd?.educationDegree}</p>
                              </div>
                            </AccordionContent>
                          </div>
                        )}
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div> }

              <p className='block mt-8 mb-2 text-xs text-center md:mb-6 md:text-base'>Anfordern für</p>
              
              <div className='relative flex flex-col gap-4 mb-8'>
                { teacherItems?.map( item => <TeacherButton key={item._id} teacherItem={item} teacherData={teacherData} updateTeacher={updateTeacher} /> ) }  
              </div>

              <div className='relative flex justify-center w-full px-4'>
                <Field name="acceptPrivacy" type="checkbox" component={AcceptPrivacyTerms} />
              </div>

              <ErrorMessage name="acceptPrivacy" component="div" className='font-bold text-center' />

              <div className='relative flex justify-center w-full px-4'>
                <button className='w-1/2 px-6 py-3 mt-4 text-xs text-center bg-transparent border border-black rounded-full cursor-pointer MD:w-2/3 md:py-6 md:text-base' type="submit" disabled={isSubmitting}>
                  Absenden
                </button>
              </div>

            </Form>
          )}
        </Formik>
        :
        <Link
          to="/" 
          className='relative px-8 py-3 text-xs text-center bg-transparent border border-black rounded-full cursor-pointer md:text-base'
        >
          <span className='block text-base text-black'>
            Herzlichen Dank für Ihr Interesse!
          </span>
          <span className='block text-base text-black'>
            Das angeforderte Informationsmaterial wird Ihnen per Email zugesendet.
          </span>
        </Link>
      }
    </div>
  )
  
}

export default UserProfileForm


const AcceptPrivacyTerms = ({ field }) => {
  return (
    <label className="block text-black">
      <input {...field} className="mr-3 form-checkbox" type="checkbox" />
      <span className="text-xs leading-tight md:text-base">Ich willige in die Datenverarbeitung gemäß der Datenschutzerklärung ein.</span>
    </label>
  )
}



const TeacherButton = ({ teacherItem, teacherData, updateTeacher }) => {

  const [isSelected, setIsSelected] = useState(false)
  
  return (
    <div
      className={`block w-full self-start py-4 md:py-6 px-5 text-center text-xs md:text-base relative border rounded-full border-black cursor-pointer ${(teacherItem.name === teacherData.name || isSelected) ? 'bg-white border-white' : ''}`} 
      onClick={() => {
        if (teacherItem.name !== teacherData.name) {
          setIsSelected(!isSelected)
          updateTeacher(teacherItem)
        }
      } }
    >
      {teacherItem.name}
    </div>
  )
}










// const request = mailjet
//   .post('send', { version: 'v3.1' })
//   .request({
//     Messages: [
//       {
//         From: {
//           Email: "info@waldorf-bw.de",
//           Name: "Landesarbeitsgemeinschaft der Freien Waldorfschulen in Baden-Württemberg e.V."
//         },
//         To: [
//           {
//             Email: values.email, //"info@vonheintschel.de",
//           }
//         ],
//         Subject: "TEST LAG!",
//         // TextPart: "Dear passenger 1, welcome to Mailjet! May the delivery force be with you!",
//         HTMLPart: "<h3>Hello!</h3><br />This is a test email!"+values.email //+JSON.stringify(values, null, 2)
//       }
//     ]
//   })

// request
//   .then((result) => {
//       console.log(result.body)
//   })
//   .catch((err) => {
//       console.log(err.statusCode)
//   })


// "Messages": [
//   {
//     "From": {
//       "Email": "info@waldorf-bw.de",
//       "Name": "Landesarbeitsgemeinschaft der Freien Waldorfschulen in Baden-Württemberg e.V."
//     }
//   }]