import React, { useState } from 'react'

const CapabilityContentButton = ({ grade, capContent, updateCapabilityContent }) => {

  const [isSelected, setIsSelected] = useState(false)
  
  return (
    <p key={capContent._id} 
      className={`relative self-start flex justify-between w-full px-4 py-2 md:py-3 my-2 md:my-3 text-left border rounded-full cursor-pointer grow-1 border-black ${isSelected ? 'bg-white border-white' : ''}`}
      onClick={() => {
        setIsSelected(!isSelected)
        updateCapabilityContent(grade, capContent)
      }}
  >
    {capContent.name}

    {isSelected && 
      <button type="button" className='inline-flex items-center justify-center text-black'>
        <span className="sr-only">Close</span>
        <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>}
  </p>
  )
}

export default CapabilityContentButton