import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import ReactGA from 'react-ga4'
import { ContentProvider } from './ContentProvider'
import { UserProfileProvider } from './UserProfileProvider'
import { MitmachenStepProvider } from './MitmachenStepProvider'
import App from './App'

import './index.css'

const TRACKING_ID = 'G-V24TZ0HLR4'
ReactGA.initialize(TRACKING_ID)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <ContentProvider>
      <UserProfileProvider>
        <MitmachenStepProvider>

          <BrowserRouter>
            <App />
          </BrowserRouter>

        </MitmachenStepProvider>
      </UserProfileProvider>
    </ContentProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()