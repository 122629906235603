import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import SanityClient from '../../SanityClient'
import { useWindowSize } from '@uidotdev/usehooks'
import BackButton from '../BackButton/BackButton'
import { useInterimContent } from '../../ContentProvider'

const TeacherList = () => {

  const size = useWindowSize()

  const { state, pathname } = useLocation()

  const { menuOpen } = useInterimContent()

  const [teacherItems, setTeacherItems] = useState(null)
  // const [teacherForUser, setTeacherForUser] = useState(null)
  const [dataForUser, setDataForUser] = useState(null)
  const [teacherShown, setTeacherShown] = useState(teacherItems)

  const getTeacher = async () => {
    const data = await SanityClient.fetch(`*[_type == "teacher"] | order(sortOrder asc) { 
      _id,
      name,
      slug,
      description,
      sortOrder,
      'trainings': refTraining{ refTraining[]->{ _id, name } },
      'relatedCaps': refCapability{ refCapability[]->{ _id, name, description } },
      'relatedQualifications': refQualification{ refQualification[]->{ _id, name } }
    }`)
    setTeacherItems(data)
  } 

  const IsActiveTeacher = id => {
    const isActive = teacherShown?.find(el => {
      if (el._id === id) {
        return true
      }
      return false
    })
    // console.log(isActive)
    return isActive
  }

  useEffect(() => {
    getTeacher()
	}, [])
  
  useEffect(() => {
    console.log('teacherShown', teacherShown)
	}, [teacherShown])
  
  // useEffect(() => {
  //   console.log(`teacherForUser --> `, teacherForUser)
  //   if (teacherForUser.length !== 0) {
  //     const operation = (arr1, arr2, isUnion = false) => arr1?.filter( a => isUnion === arr2?.some( b => a._id === b._id ) )
  //     const inBoth = (arr1, arr2) => operation(arr1, arr2, true)
  //     const teacherInBoth = inBoth(teacherItems, teacherForUser)
  //     console.log(`inBoth --> `, teacherInBoth)
  //     setTeacherShown() //teacherInBoth?.length === 0 ? teacherItems : teacherInBoth
  //   }
	// }, [teacherForUser])
  
  useEffect(() => {
    // console.log('TeacherList --> state', teacherItems)
    // console.log('TeacherList --> state', state, teacherItems)
    // console.log('teacherFiltered', state?.teacherFiltered)
    setDataForUser(state?.userProfile)
    setTeacherShown(state?.teacherFiltered ? state.teacherFiltered : teacherItems)
    // state !== null && setTeacherForUser(state?.teacherFiltered)
	}, [state, pathname, teacherItems])
  
  return (
    <>
      <BackButton />
      
      <div className={`relative flex flex-wrap w-full px-4 md:px-6 lg:px-12 divide-x divide-black h-content-mobile md:h-content divide-solid`}>
        <div className={`relative w-full h-full flex flex-wrap md:flex-nowrap items-center z-10 md:divide-x md:divide-solid md:divide-black`}>
          { teacherItems?.map((teacher, i) => {   
            // console.log(teacher._id, IsActiveTeacher(teacher._id))
            return (
              <div
                key={teacher._id}
                className={`relative flex items-center justify-center md:justify-start w-full md:w-1/3 md:h-full ${i === 0 ? 'md:pr-14' : (i === 1 ? 'md:px-14' : 'md:pl-14')}`}  // ${teacherShown.length}
              >
                {IsActiveTeacher(teacher._id) !== undefined ?
                  <Link
                      to={`${teacher.slug?.current}`} 
                      className={`cursor-pointer flex items-center justify-center w-full h-full md:justify-start text-lg md:text-xl leading-none text-left`} //${teacherShown.length === 1 ? 'md:justify-center' : 'md:justify-start'}
                      state={{ teacherData: teacher, teacherItems: teacherShown, userProfile: dataForUser }}
                  >
                    <span className='block w-3/5 md:block md:w-full'>{teacher.name} <br/> werden</span>
                  </Link>
                  : 
                  <div className={`flex items-center justify-center w-full h-full md:justify-start text-lg md:text-xl leading-none text-left opacity-10`}>
                    <span className='block'>{teacher.name} <br/> werden</span>
                  </div>
                }
              </div>
            )
          }) }  
        </div>
      </div>
    </>
  )
}

export default TeacherList