import Home  from './components/Home/Home'
import {  Route, Routes, useLocation } from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Layout from './components/Layout/Layout'
import Page from './components/Page/Page'
import Mitmachen from './components/Mitmachen/Mitmachen'
import Capabilities from './components/Capabilities/Capabilities'
import Grades from './components/Grades/Grades'
import Summary from './components/Summary/Summary'
import TeacherSingle from './components/TeacherSingle/TeacherSingle'
import SelectCapabilities from './components/SelectCapabilities/SelectCapabilities'
import TeacherList from './components/TeacherList/TeacherList'
import Moeglichkeiten from './components/Moeglichkeiten/Moeglichkeiten'
import InformierenMobile from './components/TextColumns/InformierenMobile'

import './App.css'

function App() {

  const location = useLocation()

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames='fade' timeout={500}>
        <Routes location={location}>
          
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path=":slug" element={<Page />} />
            <Route path="informieren-mobile" element={<InformierenMobile />} />

            <Route path="mitmachen" element={<Mitmachen />} >
              <Route path="lehrerin-werden" element={<SelectCapabilities />} />
              <Route path="capabilities" element={<Capabilities />} />
              <Route path="grades" element={<Grades/>} />
              <Route path="summary" element={<Summary />} />
            </Route>

            <Route path="/lehrerberufe" element={<Moeglichkeiten/>} >
              <Route index element={<TeacherList/>} />
              <Route path=":slug" element={<TeacherSingle/>} />
            </Route>            
          </Route>

        </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default App;
