import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import ContentPortableText from '../ContentPortableText/ContentPortableText'
import Backgrounds from '../Backgrounds/Backgrounds'
import SanityClient from '../../SanityClient'

const InformierenMobile = () => {

  const { state } = useLocation()

  const navigate = useNavigate()

  const [bgData, setBgData] = useState(null)

  const getBgData = async () => {
    const data = await SanityClient.fetch(`*[_type == "section" && slug.current == "informieren" ][0] | { 
      _id, 
      name,
      slug, 
      'bg': bg->{ _id, name, backgroundImages },
    }`)
    setBgData(data)
  }

  useEffect(() => {
    getBgData()
	}, [state])

  return (
    <>
      <Backgrounds items={bgData?.bg.backgroundImages} shuffle={true} autoplay={false} />

      <div 
        className='fixed z-20 inline-flex items-center justify-center left-4 top-4 md:hidden' 
        onClick={() => { navigate(-1) }}
      >
        <span className="sr-only">Close</span>
        <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>

      <div className='relative flex justify-start h-full'>
        <div className='relative pt-[60px] px-4 overflow-y-auto h-[calc(100%-60px)]'>
          { state?.title && 
            <h3 
              className={`w-full text-lg md:text-xl mb-0 md:mb-6 cursor-pointer`} 
              dangerouslySetInnerHTML={{ __html: state?.title }}>
            </h3> }

            <div className={`mt-3 pr-4`} >
              <ContentPortableText content={state?.content} />

              { state?.btn && 
                <div className='flex justify-center w-full h-full'>
                  <Link 
                    to={`${state?.btn?.internalLink !== null ? state?.btn?.internalLink.slug.current : `/${state?.btn?.route}`}`}
                    state={{ section: state?.btn?.route }}
                    className='relative block w-2/3 px-2 py-3 mb-0 text-base text-center border border-black rounded-full cursor-pointer lg:text-lg md:my-6 xl:my-12'
                  >
                    {state?.btn?.linkText}
                  </Link> 
                </div>}
            </div>
        </div>
      </div>
    </>
  )
}

export default InformierenMobile