import { useWindowSize } from '@uidotdev/usehooks'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const BackButton = ({hideBackBtn}) => {
  
  const navigate = useNavigate()
  const size = useWindowSize()

  const { pathname } = useLocation()
  // ${hideBackBtn ? 'hidden' : '' }
  return (
    <button type="button" className={`fixed z-50 inline-flex items-center justify-center text-black rounded-md cursor-pointer top-5 left-6 md:top-8 md:left-12`} onClick={ () => navigate(-1) }>
      <span className="sr-only">Close</span>
      <span className='block w-4 h-4 rotate-45 border border-transparent md:w-6 md:h-6 border-b-black border-l-black' style={{ borderWidth: '2px'}}></span>
    </button>
  )
}

export default BackButton