import React, { useState, createContext,useEffect, useContext } from 'react'
import SanityClient from './SanityClient'

const generalContentQuery = `*[_type == "generalContent"][0] | { 
    _id, 
    name,
    'menu': menu[]->{ _id, slug, name },
    'bg': bgQuestionnaire->{ _id, name, backgroundImages },
    'introGallery': introGallery,
    'introLink': introGallery{introLink->{_id, slug}},
    'introLinkTxt': introGallery{introLinkTxt}
}`

const capabilityContentQuery = `*[_type == "capabilityContent"] | order(name asc) { 
    _id, 
    name, 
    description,
    'grades': grade{ refGrade[]->{ _id, name, description, sortOrder }},
    'caps': capability{ refCapability[]->{ _id, name }}
}`

const QuestionnaireQueries = `{
	'capabilities': *[_type == "capability"] | order(name asc) { _id, name, 'grades': grade{ refGrade[]->{ _id, 'stufe': name, sortOrder } } },
    'teacher': *[_type == "teacher"] | order(sortOrder asc) { 
        _id,
        name,
        slug,
        description,
        sortOrder,
        'trainings': refTraining{ refTraining[]->{ _id, name } },
        'relatedCaps': refCapability{ refCapability[]->{ _id, name, description } } },
        'relatedQualifications': refQualification{ refQualification[]->{ _id, name } }
}`

// 'relatedGrades': grade{ refGrade[]->{ _id, 'stufe': name } }, 
// 'relatedQualifications': refQualification{ refQualification[]->{ _id, name } }, 
// requirements{}

const ContentContext = createContext()

export const ContentProvider = ({ children }) => {

    // const [route, setRoute] = useState(null)
    const [generalData, setGeneralData] = useState(null)
    const [capabilityData, setCapabilityData] = useState(null)
    const [capabilityContentData, setCapabilityContentData] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [menuOpen, setMenuOpen] = useState(false)
    
    const getGeneralData = async (queryData) => {
        const data = await SanityClient.fetch(queryData)
        setGeneralData(data)
    }

    const getData = async (queryData) => {
        setIsLoading(true)
        const data = await SanityClient.fetch(queryData)
        setCapabilityData(data)
        setIsLoading(false)
    }

    const getCapabilityContentData = async (queryData) => {
        setIsLoading(true)
        const data = await SanityClient.fetch(queryData)
        setCapabilityContentData(data)
        setIsLoading(false)
    }

    useEffect(() => {
        getGeneralData(generalContentQuery)
        getData(QuestionnaireQueries)
        getCapabilityContentData(capabilityContentQuery)
    }, [])
    
    // useEffect(() => {
    //     console.log('ROUTE --> ', route)
    //     getSectionData(sectionContentQuery)
    // }, [route])
    
    // useEffect(() => {
    //     console.log('contentData --> ', contentData)
    // }, [contentData])

    return (
        <ContentContext.Provider value={{ getGeneralData, generalData, capabilityData, capabilityContentData, menuOpen, setMenuOpen }} loading={isLoading}>
            {children}
        </ContentContext.Provider>
    )
}

export const useInterimContent = () => useContext(ContentContext)