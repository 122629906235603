import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useUserProfile } from '../../UserProfileProvider'
import { useStep } from '../../MitmachenStepProvider'
import SanityClient from '../../SanityClient'
import Text from '../Text/Text'

const SelectCapabilities = () => {

  const { userProfile, setUserProfile } = useUserProfile()
  const { step, resetStep, nextStep } = useStep()
  
  const { state, pathname } = useLocation()

  const [mitmachenData, setMitmachenData] = useState(null)

  const mitmachenBgQuery = `*[_type == "section" && slug.current == "mitmachen" ] | { 
    _id, 
    name,
    slug, 
    description,
    'bg': bg->{ _id, name, backgroundImages },
    text,
  }`

  const getMitmachenData = async (queryData) => {
    const data = await SanityClient.fetch(queryData)
    setMitmachenData(data[0]?.text)
  }

  // useEffect(() => {
  //   // console.log(`state --> `, state)step
  //   nextStep()
	// }, [pathname])
  
  useEffect(() => {
    // resetStep()
    // console.log(`SelectCapabilities --> step: `, step)
    setUserProfile([])
    getMitmachenData(mitmachenBgQuery)
	}, [])
  
  return (
    <div className='relative flex flex-wrap items-center justify-center w-full h-full'>
      
      <div className='flex flex-wrap justify-center w-full px-4 lg:px-14 md:w-1/2 lg:w-1/3 2xl:w-1/4'>
        <h1 className='w-full text-lg text-center lg:text-xl'>Interessen /<br/>Befähigungen</h1>

        <Link 
          to="/mitmachen/capabilities" 
          className='relative inline-block w-2/3 px-2 py-3 my-6 text-center border border-black rounded-full cursor-pointer'
        >
          Auswählen
        </Link>
      </div>

      <div className='absolute w-full px-4 -bottom-5 md:w-1/2 lg:w-1/3 2xl:w-1/4 md:px-0'>
        <Text content={mitmachenData?.block} />
      </div>
      
    </div>
  )
}

export default SelectCapabilities