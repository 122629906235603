// import React from 'react'
import imageUrlBuilder from '@sanity/image-url'
import { getImageDimensions } from '@sanity/asset-utils'
import SanityClient from '../SanityClient'

// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder(SanityClient)

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional parameters:
export function urlFor(source) {
  return builder.image(source)
}

export function getRatio(source) {
  return builder.image(source)
}

export function getImgDimensions(source) {
  return getImageDimensions(source)
}

export function blocksToPlainText(blocks = [], splitChar) {
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children, 
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    // join the paragraphs leaving split by two linebreaks 
    .join(splitChar ? splitChar : '\n\n')
}

// export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;