import React from 'react'
import { Link } from 'react-router-dom'

import { PortableText } from '@portabletext/react'
import Image from '../Image/Image'
// import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const serializers = {
  types: {
    // youtube: ({node}) => {
    //   console.log(node)
    //   const { url } = node
    //   const id = getYouTubeId(url)
    //   return id
    // }
  }
}

const portableTextComponents = {
  types: {
    image: ({value}) => <Image img={value} isInlineImg={true} />,
    // objText: ({value}) => 
    // youtube: ({value}) =><LiteYouTubeEmbed id={value.videoUrl} /> //<div>{value.videoUrl}</div>
  },

  marks: {
    centered: ({children}) => <span className='inline-block text-center'>{children}</span>,
    rounded: ({children}) => <span className='-translate-x-2 circle-activated'>{children}</span>,
    underline: ({children}) => <span className='underline underline-offset-4 decoration-solid decoration-current decoration-2'>{children}</span>,
    link: ({children, value}) => {
      const target = value?.href.startsWith('http') ? '_blank' : undefined
      // console.log(children, value)
      if (target === undefined) {
        return value?.href.includes('mailto') ? <a href={value?.href }>{children}</a> : <Link to={`/page/${value?.href}`}>{children}</Link> 
      } else {
        return <a href={value?.href} target={target} rel={target === '_blank' && 'noindex nofollow'} className='underline underline-offset-4'>{children}</a>
      }
    }
    // strong: ({children}) => <strong className='font-bold'>{children}</strong>,
  },

  // text-xl-mobile md:text-2xl leading-none
  block: {
    normal: ({children}) => <p className="mb-5 text-xs md:text-base">{children}</p>,
    large: ({children}) => <p className="mt-2 mb-6 text-4xl-mobile md:text-3xl lg:text-4xl">{children}</p>,
    small: ({children}) => <small className="my-2 text-xs lg:text-sm">{children}</small>,
    h1: ({children}) => <h1 className="mt-2 mb-6 text-4xl-mobile md:text-3xl lg:text-4xl">{children}</h1>,
    h2: ({children}) => <h2 className="mt-2 mb-6 text-xl-mobile md:text-2xl lg:text-2xl">{children}</h2>,
    h3: ({children}) => <h3 className="mt-2 mb-6 text-lg md:text-xl">{children}</h3>,
    h4: ({children}) => <h4 className="mt-2 mb-6 text-base md:text-xl">{children}</h4>,
    h5: ({children}) => <h5 className="mt-2 mb-6 text-base">{children}</h5>,
    // button: ({children}) => <CalltoAction>{children}</CalltoAction>, 
  },

  list: {
    bullet: ({children}) => <ul className='mb-4 list-inside xl:mb-6'>{children}</ul>,
    number: ({children}) => <ol className='mb-4 list-inside list-number xl:mb-6'>{children}</ol>,
  },

  listItem: {
    bullet: ({children}) => <li className='before:left-0 before:absolute before:content-["—"] relative text-xs leading-tight md:text-base pl-6'>{children}</li>,
    // bullet: ({children}) => <li className='relative mb-1 xl:mb-3 before:content-["\2192\a0"] before:-inset-y-1 lg:before:inset-y-0 text-sm md:text-base xl:text-xl leading-tight'>{children}</li>,
  },
}

const ContentPortableText = ({content}) => {
  return(
    <PortableText 
      value={content} 
      components={portableTextComponents}
      types={serializers}
    />
  )
}

export default ContentPortableText;