import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
// import { useWindowSize } from '@uidotdev/usehooks'
import SanityClient from '../../SanityClient'
import Backgrounds from '../Backgrounds/Backgrounds'
import { useInterimContent } from '../../ContentProvider'

const Moeglichkeiten = () => {

  const { menuOpen } = useInterimContent()

  const { state, pathname } = useLocation()

  const [bgData, setBgData] = useState(null)
 
  const getBgData = async () => {
    const data = await SanityClient.fetch(`*[_type == "background" && name == "Lehrerberufe" ] | { 
      _id, 
      backgroundImages
    }`)
    setBgData(data[0])
  }

  // useEffect(() => {
  //   console.log('Möglichkeiten --> state', state)
	// }, [state])

  useEffect(() => {
    getBgData()
	}, [])
  
  return (
    <div className={`relative w-full h-full z-10`}>
        
      <Backgrounds items={bgData?.backgroundImages} autoplay={false} />
      {/* state !== null && state?.bg?.length !== 0 ? state?.bg :  */}

      <div className={`relative w-full h-full flex flex-wrap gap-0 items-center ${menuOpen ? 'opacity-10 pointer-events-none' : ''} transition-opacity duration-200`}>
        <Outlet /> 
      </div>

    </div>
  )
}

export default Moeglichkeiten