import React, { useEffect, useRef, useState } from 'react'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import { useInterimContent } from '../../ContentProvider'
import { useWindowSize } from '@uidotdev/usehooks'
import ContentPortableText from '../ContentPortableText/ContentPortableText'
import { Link } from 'react-router-dom'
import Image from '../Image/Image'

import '@splidejs/react-splide/css/core'

function Home() {
  
  const refIntroGallery = useRef(null)

  const size = useWindowSize()
  const { generalData, menuOpen } = useInterimContent()

  const [introSlides, setIntroSlides] = useState()

  useEffect(() => {
    console.log(introSlides)
  }, [introSlides])

  useEffect(() => {
    setIntroSlides(generalData?.introGallery?.introSlider?.slides)
  }, [generalData])
  
  return (
      <div className={`relative w-full h-screen`}>
        
        <div className={`transition-opacity duration-500 relative w-full h-full z-0`} >  
          {introSlides?.length >= 1 &&
            <Splide 
              ref={ refIntroGallery }
              className='w-full h-full'
              tag='section'
              aria-label='Intro'
              hasTrack={ false }
              options={{
                type: 'fade',
                autoplay: true,
                rewind: true,
                speed: size.width >= 768 ? 500 : 600,
                easing: 'linear',
                drag: false,
                interval: 3000,
                pauseOnHover: false,
                width: '100%',
                arrows: false,
                pagination: false
              }}
            >          
              <SplideTrack>
                {introSlides?.map( slide => 
                  <SplideSlide key={slide._key}> 
                    <div className='relative flex flex-wrap justify-center w-full h-full'>  
                      <Image img={slide.slideImage?.asset} bgImg={true} />

                      <div className={`absolute h-d-screen my-auto flex flex-wrap px-6 md:px-12 w-full md:h-full z-10 ${menuOpen ? 'opacity-10' : ''} transition-opacity duration-200`}> 
                        <div className='self-start block w-full pt-8 md:w-3/4 md:pt-3 justify-self-start text-4xl-mobile md:text-4xl xl:text-3xl md:mt-0'>
                          {generalData?.introLinkTxt ? 
                            <Link className='block' to={generalData?.introLink.introLink.slug.current}>
                              <ContentPortableText content={slide.slideTextBig?.block} /> 
                            </Link>
                            : 
                            <ContentPortableText content={slide.slideTextBig?.block} />}
                        </div>

                        <div className='relative flex self-end justify-end w-full mb-14'>
                          {generalData?.introLinkTxt ? 
                            <Link to={generalData?.introLink.introLink.slug.current}>
                              <ContentPortableText content={slide.slideTextSmall?.block} /> 
                            </Link>
                            :
                            <ContentPortableText content={slide.slideTextSmall?.block} /> 
                          }
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                )}

              </SplideTrack>
            </Splide>}

          {generalData?.introLinkTxt && 
            <div className={`${menuOpen ? 'opacity-30' : ''} transition-opacity duration-200 absolute bottom-[50%] md:bottom-8 right-12 left-12 m-auto z-20 cursor-pointer`}>
              <p className='text-lg text-center xl:text-xl'>
                <Link to={generalData?.introLink.introLink.slug.current}>
                  {generalData?.introLinkTxt.introLinkTxt}
                </Link>
              </p>
            </div>}
        </div>

      </div>
  )
}

export default Home