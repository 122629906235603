import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useStep } from '../../MitmachenStepProvider'
import Backgrounds from '../Backgrounds/Backgrounds'
import SanityClient from '../../SanityClient'
import { useInterimContent } from '../../ContentProvider'
import { useUserProfile } from '../../UserProfileProvider'
import BackButton from '../BackButton/BackButton'

const Mitmachen = () => {

  const { step, resetStep } = useStep()
  const { mitmachenBgItems, setMitmachenBgItems } = useUserProfile()
  const { menuOpen } = useInterimContent()

  const mitmachenBgQuery = `*[_type == "section" && slug.current == "mitmachen" ] | { 
    _id, 
    name,
    slug, 
    description,
    'bg': bg->{ _id, name, backgroundImages },
    text,
  }`

  const [bgData, setBgData] = useState(null)
  
  const getBgData = async (queryData) => {
    const data = await SanityClient.fetch(queryData)
    setBgData(data[0]?.bg.backgroundImages)
    setMitmachenBgItems(data[0]?.bg.backgroundImages)
  }

  useEffect(() => {
    getBgData(mitmachenBgQuery)
    // resetStep()
	}, [])
  
  return (
    <div className={`relative flex flex-wrap items-center justify-center w-full h-full overflow-hidden`}>
      
      <BackButton />
      
      <Backgrounds items={bgData} shuffle={false} autoplay={false} />
      {/* step={0} */}

      <div className={`relative overflow-y-auto h-content-mobile md:h-content w-full ${menuOpen ? 'opacity-10 pointer-events-none' : ''} transition-opacity duration-200`}>
        <Outlet /> 
      </div>
      
    </div>    
  )
}

export default Mitmachen