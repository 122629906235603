import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useInterimContent } from '../../ContentProvider'
// import { useWindowSize } from '@uidotdev/usehooks'
import Text from '../Text/Text'
import BackButton from '../BackButton/BackButton'
import UserProfileForm from '../UserProfileForm/UserProfileForm'
import { blocksToPlainText } from '../helpers'

const TeacherSingle = () => {

  const { menuOpen } = useInterimContent()
  
  const { state } = useLocation()
  const { slug } = useParams()

  const [reducedUserData, setReducedUserData] = useState(null)
  const [showForm, setShowForm] = useState(false)

  const reduceUserData = () => {
    // setGradeCapContent(grades && [...grades].sort((a, b) => a.sortOrder - b.sortOrder).map(grade => {
    //   return { 
    //     name: grade.stufe,
    //     id: grade._id,
    //     items: capabilityContent?.filter(cap => cap.grades?.refGrade.find(el => el._id === grade?._id))
    //   }
    // }))

    // setUserData(data => ({
    //   ...data,
    //   capability: interest?.name,
    //   filteredTeacher: filteredTeacher
    // }))

    setReducedUserData(state?.userProfile?.map( el => {
      return {
        // _id: el._id,
        capability: el.capability,
        // capabilityContent: el.capabilityContent?.map(cc => cc?.filter(el => el.capVals?.length() !== 0)),
        capabilityContent: el.capabilityContent?.filter(el => el.capVals?.length !== 0),
        education: el.education,
        educationDegree: el.educationDegree,
        educationType: el.educationType
      }
    }))
  }

  useEffect(() => {
    console.log(`state --> `, state)
    reduceUserData()    
	}, [slug, state])
  
  useEffect(() => {
    console.log(`reducedUserData --> `, reducedUserData)  
	}, [reducedUserData])
  
  return (
    <>
      <div className='absolute cursor-pointer top-8 left-10 z-2ß' >
        <BackButton />
      </div>

      <div className={`relative flex flex-wrap w-full h-content-mobile md:h-content px-4 md:px-6 lg:px-12 z-10 overflow-auto ${menuOpen ? (showForm ? 'opacity-0 pointer-events-none' : 'opacity-10 pointer-events-none') : (showForm ? 'opacity-0 pointer-events-none' : '')} transition-opacity duration-200`}>

        <div className='relative w-full md:pr-6 md:w-2/3 lg:pr-14'>
          <Text content={state?.teacherData?.description?.block} />  
        </div>      
        
        <div className='w-full md:px-14 md:top-0 md:sticky md:w-1/3'>
          <h2 className='mt-2 mb-6 text-lg md:text-xl'>Anforderungsliste</h2>
          
          <ul className='mb-8 list-inside xl:mb-6'>
            { state?.teacherData?.trainings?.refTraining.map( item => {
              return (
                <li
                  key={item._id}
                  className='before:left-0 before:absolute before:content-["—"] relative text-xs leading-tight md:text-base pl-6'
                >
                  {item.name}
                </li>
              )
            }) }  
          </ul>

          {!showForm && <button className='px-6 py-3 mb-4 text-base text-center border border-black rounded-full cursor-pointer md:w-11/12' type='button' onClick={ () => setShowForm(true) } >
            Anforderungsliste und Informationsmaterial per Email zusenden
          </button>}
        </div>

      </div>

      { showForm &&
        <div className={`absolute h-content-mobile inset-x-0 top-16 z-10 ${menuOpen ? 'opacity-10' : ''} transition-opacity duration-200`}>
          <div className='flex flex-wrap items-center justify-center w-full h-full overflow-y-auto'>
            <div className='relative w-full px-4 pb-16 md:pt-8 md:w-1/2 lg:w-1/3 md:px-0'>
              <h2 className='my-8 text-lg text-center md:mb-8 md:text-xl'>Informationsmaterial zusenden</h2>
              
              <UserProfileForm 
                userData={reducedUserData} //state?.userProfile
                teacherData={state?.teacherData} 
                teacherItems={state?.teacherItems} //teacherDataMail
              />
            </div>
          </div>
        </div> }
    </>
  )
}

export default TeacherSingle