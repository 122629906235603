import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import SanityClient from '../../SanityClient'
import { useInterimContent } from '../../ContentProvider'
import { useWindowSize } from '@uidotdev/usehooks'

// import useWindowSize from '../../useWindowSize'

const Menu = () => {

  const logoRef = useRef(null)
  const refMenu = useRef(null)

  const size = useWindowSize()
  const { menuOpen, setMenuOpen, generalData } = useInterimContent()  
  const { state, pathname } = useLocation()

  // useEffect(() => {
  //   console.log(`path --> `, pathname)
	// }, [pathname])
  
  return (
    <div className='fixed z-50 top-4 right-4 md:top-8 md:right-12'>
        
      <div className={`relative h-10 ${menuOpen ? '' : 'space-y-1 md:space-y-2'} cursor-pointer`} onClick={() => setMenuOpen(!menuOpen)}>
        <span className={`${menuOpen ? 'rotate-45	translate-y-4 md:translate-y-2' : ''} relative block w-6 md:w-10 h-0.5 bg-black`}></span>
        <span className={`${menuOpen ? 'hidden' : 'block'} relative w-6 md:w-10 h-0.5 bg-black`}></span>
        <span className={`${menuOpen ? '-rotate-45 translate-y-3.5 md:translate-y-1.5' : ''} relative block w-6 md:w-10 h-0.5 bg-black`}></span>
      </div>

      <div ref={refMenu} className={`absolute right-0 pt-6 md:pt-3 px-0 w-auto ${menuOpen ? 'block' : 'hidden'}`}>
        <ul className='relative flex flex-col items-center justify-between'>
          <NavLink 
            to={`/`} 
            className={({isActive}) => `block w-full self-start my-1 py-1 px-8 text-right text-lg leading-none md:text-xl relative border ${isActive ? 'border-black' : 'border-transparent'} rounded-full hover:border-black cursor-pointer`}
          >
            Start
          </NavLink>

          {generalData?.menu?.map( item => {
            return (
              <NavLink 
                key={item._id}
                className={({isActive}) => `block w-full self-start my-1 py-1 px-8 text-right text-lg leading-none md:text-xl relative border ${isActive ? 'border-black' : 'border-transparent'} rounded-full hover:border-black cursor-pointer`}
                to={`/${item.slug.current}${item.slug.current === 'mitmachen' ? '/lehrerin-werden' : ''}`} 
              >
                {item.name}
              </NavLink>
            )
          })}  
        </ul>
      </div>

    </div>
  )
}

export default Menu