import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useInterimContent } from '../../ContentProvider'
import { useWindowSize } from '@uidotdev/usehooks'
import ContentPortableText from '../ContentPortableText/ContentPortableText'

// import useWindowSize from '../../useWindowSize'

const Text = ({ content }) => {

  const logoRef = useRef(null)
  const refMenu = useRef(null)

  const size = useWindowSize()
  const { route, contentData } = useInterimContent()
  const { state, pathname } = useLocation()

  // useEffect(() => {
  //   console.log(`state --> `, state)
	// }, [pathname])
  

  useEffect(() => {
    
	}, [])
  
  return (
    <ContentPortableText content={content} />
  )
  
}

export default Text