import React, { useState, createContext,useEffect, useContext } from 'react'

const MitmachenStepContext = createContext()

export const MitmachenStepProvider = ({ children }) => {

    let [step, setStep] = useState(0)

    const resetStep = () => setStep(0)

    const nextStep = () => setStep(step + 1)

    const prevStep = () => setStep(step - 1)
    
    // useEffect(() => {
    //     console.log(`MitmachenStepProvider --> step: `, step)
    // }, [step])
    
    return (
        <MitmachenStepContext.Provider value={{ step, nextStep, prevStep, resetStep }}>
            {children}
        </MitmachenStepContext.Provider>
    )
}

export const useStep = () => useContext(MitmachenStepContext)